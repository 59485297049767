/* eslint-disable no-useless-concat */
import { getZoneFromLocalStorage } from "../../../Services/LocalStorageService";
import { getURLByZone } from "../../../Services/endpoints.service"
//reusable

const GetZone = () =>{
    let currentUrl = window.location.href;
    let zonee = "";
   if(currentUrl.includes('zone=')){
    zonee = currentUrl?.split("zone=")[1];
    if(zonee?.includes('&')){
        zonee = zonee.split('&')[0];
    }
    localStorage.setItem('zone',zonee)
   }
    return zonee;
}
let zone = GetZone();
if(!zone){
    zone =  getZoneFromLocalStorage();
}
const BaseUrl =()=>{
    let url = "";
    if(zone?.toLowerCase() === 'east'){
        url = `${process.env.REACT_APP_WMS_EAST_BASEURL}`
    }else if(zone?.toLowerCase() === 'west'){
        url = `${process.env.REACT_APP_WMS_WEST_BASEURL}`
    }
    return url;
}
const OdataOrchestrationUrl = zone?.toLowerCase() === 'east'?`${process.env.REACT_APP_ORCHESTRATION_URL_EAST}`:`${process.env.REACT_APP_ORCHESTRATION_URL_WEST}`

const OdataOrchestrationPOSTUrl = zone?.toLowerCase() === 'east'?`${process.env.REACT_APP_CREATEPRODRECEIPT_URL_EAST}`:`${process.env.REACT_APP_CREATEPRODRECEIPT_URL_WEST}`

const userAccessUrl = zone?.toLowerCase() === 'east'?`${process.env.REACT_APP_USERACCESS_URL_EAST}`:`${process.env.REACT_APP_USERACCESS_URL_WEST}`;

const wareHousettingManagement = zone?.toLowerCase() === 'west'?`${process.env.REACT_APP_SETTINGMANAGEMENT_URL_WEST}`:`${process.env.REACT_APP_SETTINGMANAGEMENT_URL_EAST}`

const wmsUpdateTicketUrl = zone?.toLowerCase() === 'west' ?`${process.env.REACT_APP_WMS_UPDATE_TICKET_WEST}` :`${process.env.REACT_APP_WMS_UPDATE_TICKET_EAST }`

const baseurl=BaseUrl();
const styleColorSize_CommonUrl =  OdataOrchestrationUrl+"/StyleColors?$filter=style eq '{style}' and color eq '{color}'"
const styleColor_CommonUrl = "/StyleColors?$filter=style eq '{style}' and status eq 'Active'&$select=colorDescription, color&$expand=styleImages($select=imageName,imageID,imageURL,print)"

// for pick ticket
const getMyPickLIst = OdataOrchestrationUrl + "/Picktickets?$filter={filter}&$count=true"
const PickTickets =  OdataOrchestrationUrl+"/Picktickets?$count=true&$filter=(status eq 'Open' or status eq 'Packed') and warehouseID eq {warehouseID} &$select=pickTicket,warehouseID,warehouseCode,warehouseName,assigneeFirstName,assigneeLastName,customerName,order,customerPurchaseOrder,status,printCount,pickDate,shipviaDescription&pagesize={pagesize}&$skip={skip}"
const PickTicketsnull = OdataOrchestrationUrl + "/Picktickets?$count=true&$filter={filter} &$select=pickTicket,pickTicketID,warehouseID,warehouseCode,warehouseName,assigneeFirstName,assigneeLastName,assigneeOperatorID,assigneeEmailAddress,customerName,order,customerPurchaseOrder,status,printCount,pickDate,shipviaDescription,customerAccount&pagesize={pagesize}&$skip={skip}"
const getOtherPickLIsts = OdataOrchestrationUrl + "/Picktickets?$filter={filter}&$orderby=status desc&$orderby=createdOn desc&pagesize={pagesize}"
const getAssinedetails = OdataOrchestrationUrl + "/Picktickets?$filter=assigneeEmailAddress eq '{email}' and warehouseID eq {warehouseID} &$select=assigneeFirstName,assigneeLastName,assigneeEmailAddress,assigneeOperatorID"
const getTicketDetails =  OdataOrchestrationUrl+"/Picktickets?$expand=pickLines_BinLocations&$filter=pickticket eq '{ticket}'"
const OrderDetails = baseurl + "/orders/v1.0/order/{orderId}"
const pickticketinfo = OdataOrchestrationUrl + "/PickTicket/{pickTicket}"
const shippingLabels = OdataOrchestrationUrl + "/PickTicket/shippinglabels"
const pickTicketDetails = OdataOrchestrationUrl + "/Picktickets?$select=pickTicketID,pickTicket,status,masterPackageLabelStatus&$expand=packages($select=labelStatus,labelURI,labelType,masterPackageLabel)&$filter=pickTicketID in ({pickTicketID})"
const updateAssigne = baseurl + "/shipping/v1.0/pickticket/{pickTicketID}"
const getOpenStockDetails = OdataOrchestrationUrl+"/Inventory?$filter=color eq '{color}' and style eq '{style}' and  sizeName eq '{sizeName}' and binLocation eq '{binLocation}'and warehouseName eq '{warehouseName}'"
const updatePickTicket = zone?.toLowerCase() === 'west' ?`${process.env.REACT_APP_WMS_UPDATE_PICK_TICKET_WEST }` :`${process.env.REACT_APP_WMS_UPDATE_PICK_TICKET_EAST }`
//const getPickTickets = OdataOrchestrationUrl+"/Picktickets?$count=true&pagesize=2500&$skip=0&";
// for dashboard Widgets and Charts
const Orders = OdataOrchestrationUrl + "/Order?$filter=createdOn le {currentDate} and createdOn ge {previousDate}&pagesize=5000&$select=createdOn,bookedTotal"
const Invoices = OdataOrchestrationUrl + "/invoice?$filter=createdOn le {currentDate} and createdOn ge {previousDate}&pagesize=500&$select=createdOn&$expand=invoiceLines($select=shipTotal)"
const ProductionReceipts = OdataOrchestrationUrl + "/ProductionReceipts?$filter=receivedOn le {currentDate} and receivedOn ge {previousDate}&pagesize=10000&$select=receivedOn,receivedQuantity"
const GarmentDyeReceipts = OdataOrchestrationUrl + "/GarmentDyeReceipts?$filter=receivedOn le {currentDate} and receivedOn ge {previousDate}&pagesize=10000&$select=receivedOn,receivedQuantity"
const CutReceipts = OdataOrchestrationUrl + "/CutReceipts?$filter=receivedOn le {currentDate} and receivedOn ge {previousDate}&pagesize=10000&$select=receivedOn,receivedQuantity"
const QuantityPickedAPI = OdataOrchestrationUrl + "/PickTickets?$filter=createdOn le {currentDate} and createdOn ge {previousDate} and status ne 'Void'&$select=createdOn,pickTotal,status&pagesize=5000"
const OpenUnAssignedPickTicketAPI = OdataOrchestrationUrl + "/PickTickets?$count=true&$filter=status eq 'open' and assigneeOperatorID eq null&pagesize={pagesize}&$skip={skip}&$select=assigneeOperatorID,status"
const OpenAssignedPickTicketAPI = OdataOrchestrationUrl + "/PickTickets?$count=true&$filter=status eq 'open' and assigneeOperatorID ne null&pagesize={pagesize}&$skip={skip}&$select=assigneeOperatorID,status"
const OrderUnitsAPI = OdataOrchestrationUrl + "/Order?$filter=openTotal ne 0&pagesize={pagesize}&$skip={skip}&$select=openTotal,allocatedTotal"
const PendingInvoicesAPI = OdataOrchestrationUrl + "/PickTickets?$filter=status eq 'shipped'&$select=pickLines&$expand=pickLines($select=sizes;$expand=sizes($select=pickQuantity))&pagesize={pagesize}&$skip={skip}"
const OpenTransfersAPI = OdataOrchestrationUrl + "/warehouseTransfers?$filter=status eq 'open'&$select=openTotal&pagesize={pagesize}&$skip={skip}"
const OpenRMAsAPI = OdataOrchestrationUrl + "/rmas?$filter=status eq 'open'&$select=authorizedQuantity&pagesize={pagesize}&$skip={skip}"
const InvoiceCurrencyAPI = OdataOrchestrationUrl + "/invoice?$filter=createdOn le {currentDate} and createdOn ge {previousDate}&pagesize=500&$select=createdOn,totalAmount"
const AccountRecievableAPI = OdataOrchestrationUrl + "/Customers?pagesize=5000&$select=age30,age60,age90,age120,age30TC,age60TC,age90TC,age120TC,currencyCode"
const OrdersByReasonCodeAPI = OdataOrchestrationUrl + "/Order?$filter=(status eq 'open' or status eq 'hold') and orderStatusReasonDescription ne null&pagesize=5000&$select=orderStatusReasonDescription"
const OrderSourceAPI = OdataOrchestrationUrl + "/Order?pagesize=5000&$filter=(createdOn le {currentDate} and createdOn ge {previousDate}) and orderSource ne null&$select=orderSource,bookedTotal"

// for adjustment
const urlLocatonbyUPC =  OdataOrchestrationUrl+"/StyleColors?$expand=sizes,styleImages($select=imageName,imageID,imageURL,print),styleColorImages($select=imageName,imageID,imageURL,print,Sequence)&$filter=sizes/any(s:s/upc eq 'upcvalue')&$select=style,color,sizes,colorDescription"
const urlLocatonbyUPCadjust =OdataOrchestrationUrl+"/StyleColors?$expand=sizes($filter=upc eq 'upcvalue'),styleImages($select=imageName,imageID,imageURL,print),styleColorImages($select=ImageID,ImageName,ImageURL,Print,Sequence)&$filter=sizes/any(s:s/upc eq 'upcvalue')&$select=style,color,sizes,colorDescription"
const getLocatioDetails =  OdataOrchestrationUrl+"/inventory?$filter= upc eq '{upc}'and WarehouseId eq {warehouseId} and binLocation eq '{toLocation}' & $select = sizename,warehouseName,binLocation,openStockQuantity,pickQuantity,allocationQuantity,upc,inTransferQuantity,stockQuantity,LastCountedOn"
const CompleteAdjustmentUrl =  OdataOrchestrationUrl+"/Warehouses?$filter=WarehouseId eq {warehouseID}"
const AdjustmentByUpc =  OdataOrchestrationUrl+"/StyleColors?$expand=sizes&$filter=sizes/any(s:s/upc eq '{upc}')&$select=style,color,sizes"
const styleUrlwithsskip = OdataOrchestrationUrl+"StyleColors?$filter= style eq '{style}' and status eq 'Active'&$select=colorDescription&$orderby=colorDescription&pagesize= {pagesize} &$skip= {skip}"
const AdjustmentWithreplace = "https://apiwest.aims360.rest/styles/inventory/v1.0/replace"
const AdjustmentWithOutreplace = "https://apiwest.aims360.rest/styles/inventory/v1.0/adjust"
const getstyledetails = styleColorSize_CommonUrl + "&$expand=sizes($filter=sizeStatus eq 'Active';$select=sizename,sizestatus,sizePosition),styleColorImages($select=ImageID,ImageName,ImageURL,Print,Sequence)"
const inventoryUrl = OdataOrchestrationUrl+ "/inventory?$filter= upc eq '{upcvalue}'and WarehouseId eq {warehouseId}&$orderby=binLocationCategory desc,binLocation & $select = sizename, warehouseName, binLocation,binLocationCategory,openStockQuantity,upc,stockQuantity,LastCountedOn&pagesize= {pagesize}"
const AdjustmentByBin = OdataOrchestrationUrl + "/Inventory?$filter=warehouseID eq {warehouseId} and binLocation eq '{binLocation}' and binLocationStatus eq 'Active' and sizeStatus eq 'Active'&pagesize={pagesize}&$skip={skip}&$select=warehouseName,binLocation,binLocationCategory,style,color,sizeName,upc,stockQuantity,pickQuantity,inTransferQuantity,allocationQuantity,openStockQuantity,LastCountedOn&$count=true&$orderby=style asc,color asc"
const AdjustmentByBinNonZeroStock = OdataOrchestrationUrl + "/Inventory?$filter=warehouseID eq {warehouseId} and binLocation eq '{binLocation}' and binLocationStatus eq 'Active' and sizeStatus eq 'Active' and stockQuantity gt 0&pagesize={pagesize}&$skip={skip}&$select=warehouseName,binLocation,binLocationCategory,style,color,sizeName,upc,stockQuantity,pickQuantity,inTransferQuantity,allocationQuantity,openStockQuantity,LastCountedOn&$count=true"
const wmsAdjustmentbyupc = zone?.toLowerCase() === 'west' ?`${process.env.REACT_APP_ADJUSTMENT_UPC_WEST}` :`${process.env.REACT_APP_ADJUSTMENT_UPC_EAST }`
const wmsAdjustmentbystyle = zone?.toLowerCase() === 'west' ?`${process.env.REACT_APP_ADJUSTMENT_STYLE_WEST}` :`${process.env.REACT_APP_ADJUSTMENT_STYLE_EAST }`
const wmsAdjustmentreplacebyupc = zone?.toLowerCase() === 'west' ?`${process.env.REACT_APP_ADJUSTMENTBYREPLACE_UPC_WEST}` :`${process.env.REACT_APP_ADJUSTMENTBYREPLACE_UPC_EAST }`
const wmsAdjustmentreplacebystyle = zone?.toLowerCase() === 'west' ?`${process.env.REACT_APP_ADJUSTMENTBYREPLACE_STYLE_WEST}` :`${process.env.REACT_APP_ADJUSTMENTBYREPLACE_STYLE_EAST }`
const wmsAdjustmentReasons = OdataOrchestrationUrl + "/AdjustmentReasons";
const inventoryStyleFilterUrl = OdataOrchestrationUrl + "/Inventory?$filter=warehouseID eq {warehouseId} and binLocationID eq {binLocationID} and binLocationStatus eq 'Active' and sizeStatus eq 'Active' and {filterValue}&pagesize={pagesize}&$skip={skip}&$select=warehouseName,binLocation,style,color,sizeName,upc,stockQuantity,pickQuantity,inTransferQuantity,allocationQuantity,openStockQuantity,LastCountedOn&$count=true"

// for style
const getStockDetails =  OdataOrchestrationUrl+"/inventory?$filter= style eq '{style}' and color eq '{color}' and sizeName eq '{size}' and WarehouseId eq {warehouseId} and binLocation eq '{toLocation}'&$select=style,color,sizename,warehouseName,binLocation,openStockQuantity,pickQuantity,inTransferQuantity,stockQuantity,allocationQuantity,LastCountedOn"
const getAllBinLocationsByStyleColorSizeName = OdataOrchestrationUrl + "/Inventory?$filter= style eq '{style}' and color eq '{color}' and sizeName eq '{sizeName}'and WarehouseId eq {warehouseId} & $select =  sizename, warehouseName, binLocation,openStockQuantity,binLocationCategory,upc,binLocationID,stockQuantity &pagesize={pagesize}";
// for transfer page
const getupcdetails =  OdataOrchestrationUrl+"/Inventory?$filter= upc eq '{upc}'and WarehouseId eq {warehouseId} &$orderby=binLocationCategory desc,binLocation & $select = sizename, warehouseName, binLocation,openStockQuantity,binLocationCategory,upc,binLocationID,stockQuantity &pagesize={pagesize}";
const getLocByStyle =  OdataOrchestrationUrl+"/inventory?$filter=style eq '{styleName}' and color eq '{color}' and ([filterSizes]) and WarehouseId eq { warehouseId}&$orderby=binLocationCategory desc,binLocation &$select=sizename, warehouseName, binLocation,openStockQuantity,upc,binLocationID,binLocationCategory,stockQuantity,LastCountedOn&pagesize={pagesize}";
const styleBinLocation= OdataOrchestrationUrl+"/Inventory?$filter=style eq '{style}' and WarehouseId eq {WarehouseId} and color eq '{color}'  and sizeName eq '{sizeName}' &$orderby=binLocationCategory desc,binLocation & $select = sizename, warehouseName, binLocation,openStockQuantity,binLocationCategory,upc,binLocationID,stockQuantity,style ,color,sizeName&pagesize={pagesize}"
const getStyleDetailsBatch= OdataOrchestrationUrl+"/Inventory?$filter=style eq '{style}' and color eq '{color}' and binLocation eq '{binLocation}'and WarehouseId eq {WarehouseId} and  sizeName eq '{sizeName}'&$count=true&$select=style,color,sizeName,stockQuantity,pickQuantity,allocationQuantity,openStockQuantity,inTransferQuantity,upc,binLocation"
const GetcolorList =  OdataOrchestrationUrl+ styleColor_CommonUrl +"&$orderby=colorDescription, color&pagesize={pagesize}"
const getlocationDetails  =  baseurl+"/warehouses/v1.0/inventory?$filter= upc eq '{upc}'and WarehouseId eq { warehouseId} and binLocationID eq {toLocation} & $select = sizename,warehouseName,binLocation,openStockQuantity,pickQuantity,allocationQuantity,upc,binLocationID"
const transferStocks =  OdataOrchestrationUrl+"/Inventory?$filter= style eq '{style}' and color eq '{color}' and sizeName eq '{size}' and WarehouseId eq {warehouseId} and binLocation eq '{toLocation}'&$select=style,color,sizename,warehouseName,binLocation,openStockQuantity,pickQuantity,binLocationID,inTransferQuantity,stockQuantity,allocationQuantity"
const getWarehouseWithLocDetails = OdataOrchestrationUrl+"/InventoryExtWarehouse?$filter=WarehouseId eq {warehouseId} and style eq '{style}' and color eq '{color}' and sizeName eq '{sizeName}' and (stockQuantity gt 0 or openStockQuantity gt 0 or pickQuantity gt 0 or allocationQuantity gt 0 or inTransferQuantity gt 0)&$select=openStockQuantity,pickQuantity,allocationQuantity,inTransferQuantity,stockQuantity&pagesize={pagesize}&$count=true"
const completeTransfers =  OdataOrchestrationUrl+"/Binlocations?$filter= ([toBinLocation]  or [fromBinLocation])and  WarehouseId eq {warehouseID}&$select= binLocationID, binLocation";
const warehouseIDData =  OdataOrchestrationUrl+"/Warehouses?$filter=WarehouseId eq {warehouseID}"
const binTransfer =   OdataOrchestrationUrl+"/transfer/{warehouseId}"
const transferReasons = OdataOrchestrationUrl+"/GetReasonCodes?$count=true&$filter=Type eq 'Transfer' and Status eq 'Active'&$orderBy=Reason"

//for Production Job
const getProductionJobByWip =  OdataOrchestrationUrl+"/ProductionJobs?$filter=wipReference eq '{wipRef}' &$select=wipReference, productionJobNumber, vendorName, wipType, startDate, dueDate&$expand=productionOperations($filter=status eq '{Open}' and status ne '';$select=style, color,colorDescription, status,manufacturingOperationDescription, issuedTotal, openTotal, receivedTotal)"
const getProductionJobByJobID =  OdataOrchestrationUrl+"/ProductionJobs?$filter=productionJobNumber eq '{prodJob}' &$select=wipReference, productionJobNumber, vendorName, wipType, startDate, dueDate&$expand=productionOperations($filter=status eq '{Open}';$select=style, color,colorDescription, status,manufacturingOperationDescription, issuedTotal, openTotal, receivedTotal)"

// For Production receiving
const getProductionJobsByWipRef = OdataOrchestrationUrl+"/ProductionJobs?$filter=wipReference eq '{wipRef}' &$select=wipReference, productionJobNumber,productionJobID, vendorName,vendorCode, wipType, startDate, dueDate&$expand=productionOperations($filter=status eq 'Open' or status eq 'Hold'; $select=style, color,styleColorID,colorDescription, status,estimatedDays,numberofDependencies,manufacturingOperationDescription,manufacturingOperationCode,productionOperationID,issuedTotal, openTotal,wipCategoryID,wipCategoryDescription,cancelledTotal,damageReceivedTotal, stockReceivedTotal;$expand=sizes)&$count=true";
const getProductionJobsByWipRefCompleteStatus = OdataOrchestrationUrl+"/ProductionJobs?$filter=wipReference eq '{wipRef}' &$select=wipReference, productionJobNumber, vendorName,vendorCode, wipType, startDate, dueDate&$expand=productionOperations($filter=status eq 'Complete' ; $select=style, color,colorDescription, status,manufacturingOperationDescription, issuedTotal, openTotal, cancelledTotal,damageReceivedTotal, stockReceivedTotal;$expand=sizes)&$count=true";
const getProductionJobsByProdJob = OdataOrchestrationUrl+"/ProductionJobs?$filter=productionJobNumber eq '{prodJob}' &$select=wipReference, productionJobNumber, vendorName, vendorCode,wipType, startDate, dueDate,&$expand=productionOperations($filter=status eq 'Open' or status eq 'Hold' ; $select=style, color,colorDescription, status,manufacturingOperationDescription, issuedTotal, openTotal, cancelledTotal,damageReceivedTotal, stockReceivedTotal;$expand=sizes)&$count=true";
const getProductionJobsByProdJobCompleteStatus = OdataOrchestrationUrl+"/ProductionJobs?$filter=productionJobNumber eq '{prodJob}' &$select=wipReference, productionJobNumber, vendorName, vendorCode,wipType, startDate, dueDate,&$expand=productionOperations($filter=status eq 'Complete' ; $select=style, color,colorDescription, status,manufacturingOperationDescription, issuedTotal, openTotal, cancelledTotal,damageReceivedTotal, stockReceivedTotal;$expand=sizes)&$count=true";
const getstyleColorSizeIDByUpc = OdataOrchestrationUrl+"/StyleColors?$expand=sizes($filter =upc eq '{upc}' ;$select=upc,styleColorSizeID)&$filter=sizes/any(s:s/upc eq '{upc}')";
const getProductionJobsBystyleColorSizeID =OdataOrchestrationUrl+"/ProductionJobs?$filter=productionoperations/any(s:s/sizes/any(b:b/styleColorSizeID eq {styleColorSizeID} and (s/status eq 'Open' or s/status eq 'Hold')))&$select=wipReference, productionJobNumber, vendorName, vendorCode, wipType, startDate, dueDate&$expand=productionoperations($select=style, color,status, colorDescription, manufacturingOperationDescription, issuedTotal, openTotal, cancelledTotal, damageReceivedTotal, stockReceivedTotal;$expand=sizes($filter=styleColorSizeID eq {styleColorSizeID}))&$count=true"
const getProductionJobsBystyleColorSizeIDCompleteStatus =OdataOrchestrationUrl+"/ProductionJobs?$filter=productionoperations/any(s:s/sizes/any(b:b/styleColorSizeID eq {styleColorSizeID} and (s/status eq 'Complete')))&$select=wipReference, productionJobNumber, vendorName, vendorCode, wipType, startDate, dueDate&$expand=productionoperations($select=style, color, colorDescription,status, manufacturingOperationDescription, issuedTotal, openTotal, cancelledTotal, damageReceivedTotal, stockReceivedTotal;$expand=sizes($filter=styleColorSizeID eq {styleColorSizeID}))&$count=true"
const getstyleColorSizeIDByStyle= OdataOrchestrationUrl+"/StyleColors?$expand=sizes($filter=sizeName eq '{sizeName}'"
const getWipCatogeryDescription = OdataOrchestrationUrl+"/wipcategories?$select=wipCategoryDescription,wipCategoryID"
const getManufacturingOperations = OdataOrchestrationUrl+"/ManufacturingOperations?$select=manufacturingOperationDescription,manufacturingOperationCode"
const getVendorDetails =  OdataOrchestrationUrl+"/Vendors?filter=status eq 'Active'&$select=vendorID, vendorCode, vendorName&$count=true";
const getProdJobDetailsByJobId = OdataOrchestrationUrl+"/ProductionJobs?$filter=productionJobNumber eq '{prodJob}' &$select=wipReference, productionJobNumber, productionJobID,vendorCode,vendorName, wipType, startDate, dueDate,openTotal,receivedTotal,status&$expand=productionOperations($select=style,styleColorId, color,colorDescription,productionOperationID, status,manufacturingOperationDescription,numberofDependencies,issuedTotal, openTotal,wipCategoryID,wipCategoryDescription,manufacturingOperationCode,receivedTotal;$expand=sizes)"
const recAndCreateProdReceipt = OdataOrchestrationPOSTUrl+"/ProductionReceipt"
const getAllBinLocationsByStyleColorSize = OdataOrchestrationUrl + "/Inventory?$filter= styleColorID eq {styleColorID} and sizeName eq '{sizeName}'and WarehouseId eq {warehouseId} & $select = sizename, warehouseName, binLocation,openStockQuantity,binLocationCategory,upc,binLocationID,stockQuantity & $orderby=binLocation&pagesize={pagesize}";
//Receiving Screen
const getWipDetails =  OdataOrchestrationUrl+"/PurchaseOrders?$filter=status eq 'Open' and purchaseOrderNumber eq '{wip}' & $select=purchaseOrderNumber, vendorName, vendorCode, type, issuedDate, inWarehouseDate, received,budget,damaged, cancelled, open, useProductionTracking,createdOn,createdByFirstName,createdByLastName,createdByEmailAddress & $expand=stylePOLines($select=allocated)"
const purchaseDetails  =  OdataOrchestrationUrl+"/purchaseorder/{ordernumber}"
const getUpcPurchaseOrder =  OdataOrchestrationUrl+"/PurchaseOrders?$select=purchaseOrderNumber, vendorName,vendorCode, type, issuedDate, inWarehouseDate, received, damaged, budget, cancelled, open, useProductionTracking,createdOn,createdByFirstName,createdByLastName,createdByEmailAddress & $expand=stylePOLines($expand=sizes) & $filter=status eq 'Open' and stylePOLines/any(s:s/sizes/any(b:b/upc eq '{upc}'))"
const getStyleColor =  OdataOrchestrationUrl+ styleColor_CommonUrl;
const getStyleColorSize = styleColorSize_CommonUrl+"&$expand=sizes( $filter=sizeStatus eq 'Active';$select=sizename,sizestatus,styleColorSizeID)"
const getWipByStyleColorSize =  OdataOrchestrationUrl+"/PurchaseOrders?$filter=stylePOLines/any(s:s/style eq '{style}') and stylePOLines/any( s:s/color eq '{color}')&$expand=stylePOLines($expand=sizes($filter= [filterSizes] ))"
const postBatchWipRef = baseurl+"/WIP/v1.3/BatchWIPReceipts";
const extWhReceiving = OdataOrchestrationUrl + "/WarehouseTransfer/{Transfernumber}"
const postwhtransferReceiving = zone?.toLowerCase() === 'west' ? `${process.env.REACT_APP_POSTWHTRANSFER_WEST}`:`${process.env.REACT_APP_POSTWHTRANSFER_EAST}`;
const getCutTicketDetailsByNumber = OdataOrchestrationUrl + "/CutTickets?$expand=cutlines($expand=sizes)&$filter=cutTicket eq '{cutTicket}'and cutLines/any(s:s/sizes/any(b:b/upc ne null))";
const getCutTicketDetailsByUpc = OdataOrchestrationUrl + "/CutTickets?$expand=cutLines($expand=sizes) &$filter=cutLines/any(s:s/sizes/any(b:b/upc eq '{upc}'))&$count=true";
const getCutTicketDetailsByStyleColorSize = OdataOrchestrationUrl + "/CutTickets?$expand=cutLines($expand=sizes) &$filter=cutLines/any(s:(s/style eq '{style}' and s/color eq '{color}') and s/sizes/any(b: [filterSizes]))&$count=true";
const getBatchWipRefStatus = getURLByZone('REACT_APP_GET_BATCH_STATUS',zone)+ "/jobId='{jobId}'"
const getDefaultBinLoc = OdataOrchestrationUrl + "/Warehouses?$filter=warehouseId eq {warehouseID}&$select=defaultBinLocations,EnableBinLocations&$expand=defaultBinLocations";
const getGarmentDyesByNumber =  OdataOrchestrationUrl+"/GarmentDyes?$filter=status eq 'Open' and garmentDye eq '{garmentDye}'&$select=vendorName,vendorCode,inWarehouseDate, useProductionTracking,createdOn,createdByFirstName,createdByLastName,createdByEmailAddress,garmentDye,status,budgetTotal,openTotal,receiveTotal,damageTotal,cancelledTotal,wipTotal,allocatedTotal,openForAllocationTotal,dueDate,estimatedStartDate,estimatedCompleteDate&$expand=dyeLines($expand=sizes)"
const getGarmentDyesByUPC  =  OdataOrchestrationUrl+"/GarmentDyes?$filter=status eq 'Open' and dyeLines/any(s:s/sizes/any(b:b/upc eq '{upc}'))&$select=vendorName,vendorCode,inWarehouseDate, useProductionTracking,createdOn,createdByFirstName,createdByLastName,createdByEmailAddress,garmentDye,status,budgetTotal,openTotal,receiveTotal,damageTotal,cancelledTotal,wipTotal,allocatedTotal,openForAllocationTotal,dueDate,estimatedStartDate,estimatedCompleteDate&$expand=dyeLines($expand=sizes)"
const getGarmentDyesByStyleColorSize =  OdataOrchestrationUrl+"/GarmentDyes?$filter=status eq 'Open' and dyeLines/any(s:s/style eq '{style}') and dyeLines/any( s:s/color eq '{color}')&$select=vendorName,vendorCode,inWarehouseDate, useProductionTracking,createdOn,createdByFirstName,createdByLastName,createdByEmailAddress,garmentDye,status,budgetTotal,openTotal,receiveTotal,damageTotal,cancelledTotal,wipTotal,allocatedTotal,openForAllocationTotal,dueDate,estimatedStartDate,estimatedCompleteDate&$expand=dyeLines($expand=sizes($filter= [filterSizes] ))"

// for inquiry page
const inquiryByUpc=  OdataOrchestrationUrl+"/Inventory?$filter=warehouseID eq {warehouseID} and upc eq '{upc}' and ((binLocationCategory ne 'primarybin' and stockQuantity ge 1) or binLocationCategory eq 'primarybin' ) &orderby=binLocationCategory desc,binLocation &$select=binLocation, stockQuantity, binLocationCategory,allocationQuantity,openStockQuantity,inTransferQuantity,pickQuantity,styleColorDefaultImageURL,styleDefaultImageURL&pagesize={pagesize}&$skip={skip}&$count=true";
const inquiryByUpcGrid=  OdataOrchestrationUrl+"/Inventory?$filter=warehouseID eq {warehouseID} and upc eq '{upc}' and ((binLocationCategory ne 'primarybin' and stockQuantity ge 1) or binLocationCategory eq 'primarybin' ) &orderby=binLocationCategory desc,binLocation &$select=binLocation, stockQuantity, binLocationCategory,allocationQuantity,openStockQuantity,inTransferQuantity,pickQuantity,styleColorDefaultImageURL,styleDefaultImageURL&pagesize={pagesize}";
const inquiryByBin= OdataOrchestrationUrl+"/Inventory?$filter=warehouseID eq {warehouseID} and binLocation eq '{bin}' and sizeStatus eq 'Active' and stockQuantity ne 0 & $orderby=sizePosition,stockQuantity & $select=style, color, sizeName, stockQuantity, binLocationCategory,sizePosition,allocationQuantity,pickQuantity,binLocation,openStockQuantity,inTransferQuantity,styleColorDefaultImageURL,styleDefaultImageURL &pagesize={pagesize}";
const inquiryByUpcStyle=  OdataOrchestrationUrl+"/StyleColors?&$expand=styleImages($select=imageName,imageID,imageURL,print)&$expand=sizes,styleColorImages($select=imageName,imageID,imageURL,print,Sequence)&$filter=sizes/any(s:s/upc eq '{upc}')&$select=style,color,sizes,colorDescription";
const inquiryByStyle= OdataOrchestrationUrl+"/StyleColors?$filter=color eq '{color}' and style eq '{styleName}' and status eq 'Active'&$select=styleColorID,color,colorDescription ";
const inquiryBYColorId= OdataOrchestrationUrl+"/Inventory?$filter=warehouseID eq { warehouseID} and styleColorID  eq {colorId} and ([filterSizes]) and stockQuantity ne 0 and ((binLocationCategory ne 'primarybin' and stockQuantity ge 1) or binLocationCategory eq 'primarybin' ) &orderby=binLocationCategory desc,binLocation &$select=binLocation, stockQuantity, binLocationCategory,pickQuantity,allocationQuantity,sizeName,color,style,openStockQuantity,inTransferQuantity&pagesize={pagesize}&$skip={skip}&$count=true";
const inquiryByBinBySkip=OdataOrchestrationUrl+"/Inventory?$filter= warehouseID eq { warehouseID} and binLocation eq '{bin}' and stockQuantity ne 0 & $orderby=sizePosition,stockQuantity &$select=style, color, sizeName, stockQuantity, binLocationCategory,sizePosition,pickQuantity,allocationQuantity,openStockQuantity,inTransferQuantity,styleDefaultImageURL,styleColorDefaultImageURL &pagesize= {pagesize} &$skip= {_skip}&$count=true";
const inquiryByUPCPrimaryLinked=OdataOrchestrationUrl+"/Styleprimarybins?$filter=warehouseID eq {warehouseID} and upc eq '{upc}' and  binLocationCategory eq 'Primary' &$select=binLocationCategory,binLocation,stockQuantity,pickQuantity,openStockQuantity,inTransferQuantity"
const inquiryByStylePrimaryLinked=OdataOrchestrationUrl+"/Styleprimarybins?$filter=warehouseID eq {warehouseID} and color eq '{color}' and style eq '{styleName}' and ([filterSizes]) and  binLocationCategory eq 'Primary' &$select=binLocationCategory,binLocation,stockQuantity,pickQuantity,sizeName,openStockQuantity,inTransferQuantity"

//for cut ticket edit
const getCutTicketDetails = OdataOrchestrationUrl + "/cutticket/{cutTicket}";
const getCustomFieldListValues = baseurl + "/codes/v1.0/CustomFieldListValues?$filter=customFieldID eq {customFieldID}";
const postUpdateCutTicket=baseurl+"/WIP/v1.0/cutticket/{cutTicketNumber}";
const getCustomFieldsData=baseurl+"/codes/v1.0/CustomFields?pageSize=250&$filter=status eq 'Active'";
const updateCutTicket = zone?.toLowerCase() === 'west' ?`${process.env.REACT_APP_WMS_UPDATE_CUT_TICKET_WEST }` :`${process.env.REACT_APP_WMS_UPDATE_CUT_TICKET_EAST }`
const getCustomFields = zone?.toLowerCase() === "west" ?`${process.env.REACT_APP_WMS_GET_CUSTOMFIELDS_WEST }`  + "?$filter=status eq 'Active'&pagesize=250&$count=true" :`${process.env.REACT_APP_WMS_GET_CUSTOMFIELDS_EAST  }` + "?$filter=status eq 'Active'&pagesize=250&$count=true"
// Validate Bin Location
const validateBinLocation = OdataOrchestrationUrl + "/Binlocations?$select=binLocation,binLocationCategory,binLocationStatus&$filter=binLocation eq '{binLocation}'"
const validateBinLocationwithWareHouse = OdataOrchestrationUrl + "/Binlocations?$select=binLocation,binLocationID,binLocationCategoryCode,binLocationCategory,binLocationCategoryID,binLocationStatus&$filter=binLocation eq '{binLocation}' and warehouseID eq {warehouseID}"
// for warehouse
const warehouseData = OdataOrchestrationUrl+"/Warehouses?$filter=status eq 'Active'&$select=warehouseID,status,warehouseName,warehouseCode"
const allWareHouseData=OdataOrchestrationUrl+"/Warehouses?$select=warehouseID,status,warehouseName,warehouseCode"
const getBatchWHData=OdataOrchestrationUrl+"/Inventory?$filter=warehouseID eq {warehouseID} and binLocationID eq {binLocationID} and upc eq '{upc}'&$count=true&$select=style,color,sizeName,stockQuantity,pickQuantity,allocationQuantity,openStockQuantity,inTransferQuantity,upc";
const getBatchWHDataByBin=OdataOrchestrationUrl+"/Inventory?$filter=warehouseID eq {warehouseID} and binLocation eq '{binLocationID}' and upc eq '{upc}'&$count=true&$select=style,color,sizeName,stockQuantity,pickQuantity,allocationQuantity,openStockQuantity,inTransferQuantity,upc,binLocation";

// for Getting Error Messages
const getMessages = baseurl+"/exceptionmanagement/odata/v1/messages?$filter={categories} &$select=categoryName,message,messageCode"

//get the bin loaction list as per the warehouse id
const getBinLocationsbyWHId = OdataOrchestrationUrl + "/Binlocations?$filter=warehouseID eq {warehouseID} and binLocationStatus eq 'Active' &$count=true&$select=binLocationID,binLocation,warehouseID,warehouseName,binLocationStatus";
// for WMS Aure function
const EastAuthUrl =`${process.env.REACT_APP_WMS_EAST_FUNCTION_URL}`;
const WestAuthUrl =`${process.env.REACT_APP_WMS_WEST_FUNCTION_URL}`;

// for grid
const styles = OdataOrchestrationUrl + "/styleColors?$count=true&pagesize={pagesize}&$skip={skip}&$orderby=style asc,color asc{expand}{filters}"
const getstyledetailsForGridWithOutSizes = OdataOrchestrationUrl + "/StyleColors?$expand=styleImages($select=imageName,imageID,imageURL,print),styleColorImages($select=imageName,imageID,imageURL,print)&$count=true&pagesize={pagesize}&$skip={skip}&$orderby=style asc,color asc"
const getstyledetailsForGrid = OdataOrchestrationUrl + "/StyleColors?$expand=Sizes,styleImages($select=imageName,imageID,imageURL,print),styleColorImages($select=imageName,imageID,imageURL,print)&$count=true&pagesize={pagesize}&$skip={skip}&$orderby=style asc,color asc"
const getGridSearchWithSize = OdataOrchestrationUrl + "/StyleColors?$expand=Sizes($select=sizeName,upc,sizeStatus,styleColorSizeID,minimumQuantity,stockQuantity,pickQuantity,wipQuantity,orderQuantity,shipQuantity,ots_i,ots),styleImages($select=imageName,imageID,imageURL,print),styleColorImages($select=imageName,imageID,imageURL,print)&$filter=contains(style, '{style}') or contains(color, '{color}') or sizes/any(size: contains(size/sizeName, '{sizeName}')) or sizes/any(size: contains(size/upc, '{upcValue}'))&$orderby=style asc,color asc&&$select=style,color,description,alternateDescription,colorDescription,type,divisionCode,divisionName,styleGroupCode,styleGroupDescription,seasonCode,seasonName,scale,bodyCode,bodyDescription,available,styleColorID,createdByEmailAddress,createdOn,lastModifiedByEmailAddress,lastModified,status&$count=true"
const getGridSearchWithoutSize = OdataOrchestrationUrl + "/StyleColors?$expand=styleImages($select=imageName,imageID,imageURL,print),styleColorImages($select=imageName,imageID,imageURL,print)&$filter=contains(style, '{style}') or contains(color, '{color}')&$orderby=style asc,color asc&$count=true&$select=style,color,description,alternateDescription,colorDescription,type,status,stockTotal,pickTotal,wipTotal,orderTotal,ots,divisionCode,divisionName,styleGroupCode,styleGroupDescription,seasonCode,seasonName,scale,bodyCode,bodyDescription,available,cancel,wholesalePrice,replenishmentCode,replenishmentDescription,styleColorID"
const styleBySizeAll = OdataOrchestrationUrl + "/styleColors?$expand=sizes($select=sizeName,upc,sizeStatus,styleColorSizeID,minimumQuantity,stockQuantity,pickQuantity,wipQuantity,orderQuantity,shipQuantity,ots_i,ots),styleImages($select=imageName,imageID,imageURL,print),styleColorImages($select=imageURL,print)&$select=style,color,description,alternateDescription,colorDescription,type,divisionCode,divisionName,styleGroupCode,styleGroupDescription,seasonCode,seasonName,scale,bodyCode,bodyDescription,available,styleColorID,createdByEmailAddress,createdOn,lastModifiedByEmailAddress,lastModified,status&$count=true&pagesize={pagesize}&$skip={skip}&$orderby=style asc,color asc"
const styleAll = OdataOrchestrationUrl + "/styleColors?$expand=styleImages($select=imageName,imageID,imageURL,print),styleColorImages($select=imageURL,print)&$select=style,color,description,alternateDescription,colorDescription,type,status,stockTotal,pickTotal,wipTotal,orderTotal,ots,divisionCode,divisionName,styleGroupCode,styleGroupDescription,seasonCode,seasonName,scale,bodyCode,bodyDescription,available,cancel,wholesalePrice,replenishmentCode,replenishmentDescription,styleColorID&$count=true&pagesize={pagesize}&$skip={skip}&$orderby=style asc,color asc"
const styleBySizeActive = OdataOrchestrationUrl + "/styleColors?$filter=status eq 'Active'&$expand=sizes($select=sizeName,upc,sizeStatus,styleColorSizeID,minimumQuantity,stockQuantity,pickQuantity,wipQuantity,orderQuantity,shipQuantity,ots_i,ots),styleImages($select=imageName,imageID,imageURL,print),styleColorImages($select=imageURL,print)&$select=style,color,description,alternateDescription,colorDescription,type,divisionCode,divisionName,styleGroupCode,styleGroupDescription,seasonCode,seasonName,scale,bodyCode,bodyDescription,available,styleColorID,createdByEmailAddress,createdOn,lastModifiedByEmailAddress,lastModified,status&$count=true&pagesize={pagesize}&$skip={skip}&$orderby=style asc,color asc"
const styleActive = OdataOrchestrationUrl + "/styleColors?$filter=status eq 'Active'&$expand=styleImages($select=imageName,imageID,imageURL,print),styleColorImages($select=imageURL,print)&$select=style,color,description,alternateDescription,colorDescription,type,status,stockTotal,pickTotal,wipTotal,orderTotal,ots,divisionCode,divisionName,styleGroupCode,styleGroupDescription,seasonCode,seasonName,scale,bodyCode,bodyDescription,available,cancel,wholesalePrice,replenishmentCode,replenishmentDescription,styleColorID&$count=true&pagesize={pagesize}&$skip={skip}&$orderby=style asc,color asc"
const importFilejobStatus = OdataOrchestrationUrl + "/JobDetails?$filter=moduleType eq 'Import File' and userEmail eq '{userEMail}'";
const importFileInProgressJob = OdataOrchestrationUrl + "/JobDetails?$filter=moduleType eq 'Import File' and userEmail eq '{userEMail}' and status eq '{status}'";
const importFileInProgressJobForGrid= OdataOrchestrationUrl +"/JobDetails?$filter= userEmail eq '{userEMail}'{filter}";
const getAllViews = OdataOrchestrationUrl + "/grid/views"
const getModuleViews = OdataOrchestrationUrl + "/grid/viewsByModule/{moduleId}"

// for orders
const orderCustomFields = OdataOrchestrationUrl+"/CustomFields?$filter=status eq 'Active' and  entityName eq 'Order'"

//For QuickBooks 
const aimsCustomersURL = OdataOrchestrationUrl+"/Customers?$count=true&$skip={skip}&pagesize={pagesize}&$filter=status eq 'Active'";
const qkOauthURL = 'https://appcenter.intuit.com/app/connect/oauth2?client_id={ClientId}&scope=com.intuit.quickbooks.accounting&redirect_uri={RedirectURL}&response_type=code&state=PlaygroundAuth#/OpenIdAuthorize';

// for SAGE100
const sage = OdataOrchestrationUrl+"/codes";
const sageInvoiceNotExported = OdataOrchestrationUrl+"/codes/invoices?$count=true&exportedTrue={exported}&$skip={skip}&pagesize={pagesize}&top={top}";
const sageInvoiceExported = OdataOrchestrationUrl+"/codes/invoices?$count=true&exportedTrue={exported}&$top={top}&previous={previous}&pagesize={pagesize}";
const sageCustomer = OdataOrchestrationUrl+'/codes/customers?$count=true&exportedTrue={exported}&$skip={skip}&pagesize={pagesize}&{prvious}&{top}';
//for loop RMA
const LoopRmaUrl = zone?.toLowerCase() === 'west' ?`${process.env.REACT_APP_LOOPRMA_WEST }` :`${process.env.REACT_APP_LOOPRMA_EAST }`
const getRMAReasonCode =OdataOrchestrationUrl +"/LoopRma/getrmareasondetails";
const getShopifyStoredetails= LoopRmaUrl + "/getshopifystores";
const getOrderStatusDetails= LoopRmaUrl +"/getOrderStatusDetails";
const saveConfigureDetails = LoopRmaUrl + "/addstoreconfigurations";
const updateConfigurationStore = LoopRmaUrl + "/deactivatestore";
const getLoopReturnSettings=OdataOrchestrationUrl +"/GetShopifyStoredetails"
const OdataJobDetailsEndPoint=OdataOrchestrationUrl + "/JobDetails?$filter=jobId eq {jobID}"

const looprmaReProcessUrl=LoopRmaUrl +"/rma/reprocess/{jobId}"
//for QuickBooks
const quickBooksCustomer = LoopRmaUrl+"/quickbooks/Getcustomers";
const quickBooksAccessToken = LoopRmaUrl+"/quickbooksonlineconnection/accesskey";
const quickBooksRevokeToken = LoopRmaUrl+"/quickbooksonlineconnection/revokeaccesskey";
const quickbooksLoginStatus =LoopRmaUrl+"/quickbooksonlineconnection/quickBookUserLoginStatus";
const  quickBooksSettings= LoopRmaUrl+"/getQuickBooks/settings";

//for Redis sync process
const redisManagementAPI = zone?.toLowerCase() === 'west' ?`${process.env.REACT_APP_REDIS_MANAGEMENT_WEST }` :`${process.env.REACT_APP_REDIS_MANAGEMENT_EAST }`

//for garment Dye
const getGarmentDyeDetails= OdataOrchestrationUrl +"/GarmentDye/{garmentdye}";
const getWarehouseDetailsByID=OdataOrchestrationUrl + "/Warehouses?$filter=warehouseID eq {warehouseID}";
const getVendorDetailsID=OdataOrchestrationUrl +"/Vendors?$filter=vendorID eq {vendorID}";

//userAccess WareHouse
const warehouseURL= OdataOrchestrationUrl +"/Warehouses?$count=true";
const warehouseByRole=OdataOrchestrationUrl +"/Getwarehousebyrole?roleid={roleid}";
const customFieldByRole=OdataOrchestrationUrl+"/CutticketCustomFieldsByRole?roleid={roleid}&$filter=status eq 'Active'";

//for losanftp
const LosanFTPUrl =`${process.env.REACT_APP_LOSAN_FTP}`;
const getLosanFtpJobDetailsurl=OdataOrchestrationUrl+"/JobDetails?filter=moduleType eq 'LosanFtp'";
const getWholesaleFtpJobDetailsurl=OdataOrchestrationUrl+"/JobDetails?filter=moduleType eq 'WholesaleFtp'";
const getLosanFTPDetialsUrl=LosanFTPUrl+"/Losan_Details";
const getHandleRetryJob="https://staging-runwaylosanftpupload-west1.aims360runway.com/api/WholesaleFTP";

//Warehuse Transfer_Report
const getAllWareHouseTransfersURL = OdataOrchestrationUrl+"/GetWarehouseTransfers?$count=true&$orderby=CreatedOn desc{filters}";
const getWareHouseTransfersURL = OdataOrchestrationUrl+"/GetWarehouseTransfers?$count=true&$skip={skip}&$top={top}&$orderby=CreatedOn desc{filters}";

 //for Runway Jobs
 const runwayJobs = OdataOrchestrationUrl + "/JobDetails?$select=id,jobId,jobDetails,jobRunId,jobDetails,jobRunId,jobType,moduleType,triggerTime,status,publishLink,userEmail,fileName,clientId,purgeDate,successCount,failureCount,timeOutMinutes,createdBy,createdOn,statusText{filters}"
 
//vendor po urls
const getDivisionurl= OdataOrchestrationUrl+"/DivisionCode?$count=true&$filter=divisionName ne ' '";
const getVendorPOurl= OdataOrchestrationUrl +"/Vendors?$filter=vendorName ne ' '";
const getTermCodeurl= OdataOrchestrationUrl +"/TermCode?$count=true&$filter=termDescription ne ' '";
const getSpecialInstructionurl= OdataOrchestrationUrl +"/SpecialInstructionCode?$count=true&$filter=specialInstructionDescription ne ' '";
const getCountryUrl= OdataOrchestrationUrl +"/CountryCode?$count=true&$filter=countryName ne ' '";
const getShipViaCodeurl= OdataOrchestrationUrl +"/ShipViaCode?$count=true&$filter=shipviaDescription ne ' '"

//QuickBooks
const getInstalledApps = OdataOrchestrationUrl + "/RunwayIntegrationsList?$expand=InstallledAppsSettings";

const AuthenticateURL =()=>{
    let baseurl =""
    if (zone?.toLowerCase() === 'east') {
       baseurl =EastAuthUrl;
    }
    else if (zone?.toLowerCase() === 'west') {
       baseurl=WestAuthUrl;
    }
    return baseurl;
}
const SetHeaders = () => {
    let config ={}
    if (zone?.toLowerCase() === 'east') {
        config = {
            withCredentials: true,
            headers: {
               // 'Authorization': `Bearer ${getTokenFromLocalStorage()}`,
                "x-functions-key":`${process.env.REACT_APP_WMS_EAST_FUNCTION_KEY}`
            }
        }
    }
    else if (zone?.toLowerCase() === 'west') {
        config = {
            withCredentials: true,
            headers: {
                //'Authorization': `Bearer ${getTokenFromLocalStorage()}`,
                 "x-functions-key":`${process.env.REACT_APP_WMS_WEST_FUNCTION_KEY}`
            }
        }
    }
    return config;
}


export {
    PickTickets, getTicketDetails, getOtherPickLIsts, PickTicketsnull, OrderDetails, pickticketinfo, pickTicketDetails, getMyPickLIst, getAssinedetails, updateAssigne, warehouseIDData,
    getstyledetails, styleUrlwithsskip, AdjustmentByUpc, binTransfer,transferReasons, getWarehouseWithLocDetails, getMessages,styleBySizeActive,styleAll,styleActive,styleBySizeAll,getAllViews,
    CompleteAdjustmentUrl, getLocatioDetails, urlLocatonbyUPC,urlLocatonbyUPCadjust,importFilejobStatus,AdjustmentByBin,
    getStockDetails, getupcdetails, getLocByStyle, getlocationDetails, transferStocks, completeTransfers,inventoryStyleFilterUrl,
    getProductionJobByWip, getProductionJobByJobID,getstyleColorSizeIDByStyle,getProductionJobsBystyleColorSizeID,getProductionJobsBystyleColorSizeIDCompleteStatus,getstyleColorSizeIDByUpc,getProductionJobsByProdJob,getProductionJobsByProdJobCompleteStatus,
    getProductionJobsByWipRef,getProductionJobsByWipRefCompleteStatus,getProdJobDetailsByJobId,recAndCreateProdReceipt,getVendorDetails,
    getAllBinLocationsByStyleColorSize,getWipCatogeryDescription,getManufacturingOperations,wareHousettingManagement,
    getWipDetails, purchaseDetails, getUpcPurchaseOrder, getStyleColor, getStyleColorSize, getWipByStyleColorSize, postBatchWipRef, getBatchWipRefStatus,extWhReceiving,postwhtransferReceiving,
    inquiryByUpc,inquiryByUpcGrid, inquiryByBin, inquiryByUpcStyle, inquiryByStyle, inquiryBYColorId, inquiryByBinBySkip, inquiryByUPCPrimaryLinked, inquiryByStylePrimaryLinked, AuthenticateURL, SetHeaders,
    AdjustmentWithreplace, AdjustmentWithOutreplace, warehouseData,getCustomFieldsData,getBinLocationsbyWHId,postUpdateCutTicket,getCustomFieldListValues,getCutTicketDetails,updatePickTicket,updateCutTicket,getCustomFields,GetcolorList,inventoryUrl,validateBinLocation,
    getCutTicketDetailsByUpc,getCutTicketDetailsByStyleColorSize,getCutTicketDetailsByNumber,BaseUrl,wmsAdjustmentreplacebystyle,wmsAdjustmentreplacebyupc,
    wmsAdjustmentbystyle,wmsAdjustmentbyupc,getDefaultBinLoc,getBatchWHData,getBatchWHDataByBin,getstyledetailsForGrid,getGridSearchWithSize,getGridSearchWithoutSize,getstyledetailsForGridWithOutSizes,getAllBinLocationsByStyleColorSizeName,
    getGarmentDyeDetails,getWarehouseDetailsByID,getVendorDetailsID,getRMAReasonCode,getOrderStatusDetails,getShopifyStoredetails,saveConfigureDetails,updateConfigurationStore,OdataJobDetailsEndPoint,looprmaReProcessUrl,allWareHouseData,
    warehouseURL,warehouseByRole,customFieldByRole,styles,getModuleViews,AdjustmentByBinNonZeroStock,validateBinLocationwithWareHouse,
    Orders,Invoices,ProductionReceipts,GarmentDyeReceipts,CutReceipts,QuantityPickedAPI,OpenUnAssignedPickTicketAPI,OpenAssignedPickTicketAPI,OrderUnitsAPI,PendingInvoicesAPI,OpenTransfersAPI,
    OpenRMAsAPI,InvoiceCurrencyAPI,AccountRecievableAPI,OrdersByReasonCodeAPI,OrderSourceAPI,
    getGarmentDyesByNumber,getGarmentDyesByUPC,getGarmentDyesByStyleColorSize,wmsAdjustmentReasons,getHandleRetryJob,getLosanFTPDetialsUrl,getLosanFtpJobDetailsurl,getWholesaleFtpJobDetailsurl,
    getAllWareHouseTransfersURL,getWareHouseTransfersURL,importFileInProgressJob,sage,OdataOrchestrationUrl,userAccessUrl,orderCustomFields,
    sageCustomer,sageInvoiceExported,sageInvoiceNotExported,importFileInProgressJobForGrid,styleBinLocation,getStyleDetailsBatch,getDivisionurl,getVendorPOurl,getTermCodeurl,getSpecialInstructionurl,getCountryUrl,getShipViaCodeurl,runwayJobs,aimsCustomersURL,quickBooksCustomer,quickBooksAccessToken,quickBooksRevokeToken,quickbooksLoginStatus,qkOauthURL,getInstalledApps,quickBooksSettings,LoopRmaUrl,
    getOpenStockDetails,getLoopReturnSettings,wmsUpdateTicketUrl,shippingLabels,redisManagementAPI
}